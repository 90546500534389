import React from 'react';
import './Footer.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../assets/images/recnoor.png'
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

function Footer() {
  return (
    <footer className="text-light footer-with-bg">
      <div className="containerx d-flex justify-content-center ">
        <div className="contentcontetxad d-flex  w-100 flex-wrap justify-content-between">
          <div className="logox col-md-2">

            <ul className="list-unstyled">
              <li><a href="/">
                <img src={logo} alt="" height={80} width={80} />
              </a></li>
              <li className='mt-3'>
              © 2024 NOOR RIDES PASSENGERS TRANSPORT L.L.C
              </li>
              <li className='mt-3'>
             <Link    target="_blank"
                  to="https://www.instagram.com/noorride_dubai/"><FaFacebook style={{height:24,width:24,marginRight:5}}/></Link>
             <Link    target="_blank"
                  to="https://www.linkedin.com/company/noorride"><FaLinkedin style={{height:24,width:24,marginRight:5}}/></Link>
             <Link       target="_blank"
                  to="https://www.instagram.com/noorride_dubai/"><FaInstagram style={{height:24,width:24,marginRight:5}}/></Link>


              </li>
            </ul>

          </div>
          <div className="maincontentx d-flex">


            <div className="footercontentxone d-flex ">


              <div className="">
                <h5 className='Footer-Head'>Company</h5>
                <ul className="list-unstyled w-70">
                  <li><Link to="/about">About us</Link></li>
                  <li><Link to="/book">Book a ride</Link></li>
              
                  <li><Link to="/contact">Contact us</Link></li>
                </ul>
              </div>
              <div className="">
                <h5 className='Footer-Head'>Driver</h5>
                <ul className="list-unstyled">
                  {/* <li><a href="#">Become a <br /> driver</a></li> */}
                  <li>     <Link
                target="_blank"
                    to="https://noorride.com/privacy.html"
                    className="text-body-color hover:text-primary mb-2 inline-block text-base leading-loose"
                  >
                General terms & condition
                  </Link></li>
                </ul>
              </div>
            </div>
            <div className="footercontenttwox d-flex">


              <div className="">
                <h5 className='Footer-Head'>Legal</h5>
                <ul className="list-unstyled">
                  <li>      <Link
                  target="_blank"
                    to="https://noorride.com/privacy.html"
                    className="text-body-color hover:text-primary mb-2 inline-block text-base leading-loose"
                  >
                    Privacy policy
                  </Link></li>
                </ul>
              </div>
              <div className="">
                <h5 className='Footer-Head'>Contact Us</h5>
                <ul className="list-unstyled">
                  <li><a href='tel:+97148718604'><i class="bi bi-telephone-fill"></i>+97148718604</a></li>
                  <li><a href='mailto:info@noorride.com'><i class="bi bi-envelope-fill"></i> Info@noorride.com</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;