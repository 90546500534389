import React from 'react'
import Hero from '../components/home/hero'
import Testimonial from '../components/home/testimonial'
import Service from '../components/home/service'
import Whynoor from '../components/home/whynoor'
import Everykm from '../components/home/everykm'
import Footer from '../components/Footer/Footer'
import Whatsappbtn from '../components/layout/whatsapp'

export default function Home() {
  return (
    <>
    <Hero/>
    <Testimonial/>
    <Service/>
    <Whynoor/>
    <Everykm/>
    <Footer/>
    <Whatsappbtn/>
    </>
  )
}
