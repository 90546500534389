import React from 'react'
import aboutimage from '../../assets/images/aboutnoor.png'
import './about.css'
export default function Aboutnoor() {
  return (
    <>
   <div className='about' >
   <div className='container '>

<div className='row py-4'>
<div className=' col-lg-6 col-sm-12'>

<p style={{fontSize:"2.5rem"}}>About <span style={{color:"#a1a1a1",fontSize:"2.5rem"}}> Noor Rides</span></p>
<p style={{fontWeight:"bold",fontSize:"1.5rem"}}>Tap-Ride-Explore</p>
</div>
<div className=' col-lg-6 col-sm-12'>
<p style={{fontWeight:"bold"}}>Quickly secure a safe, affordable ride anywhere in Dubai with Noor. Our app makes it easy, whether you're heading to the airport, exploring, or running errands. Choose our sustainable, eco-friendly fleet for a greener journey and the future of urban travel.
</p>
</div>


</div>
<div className='about__m'>

</div>
    </div>
   </div>
    </>
  )
}
