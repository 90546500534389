import React from 'react'
import service1 from '../../assets/images/service1.png'
import service2 from '../../assets/images/service2.png'
import service3 from '../../assets/images/service3.png'
import service4 from '../../assets/images/service4.png'
import service5 from '../../assets/images/service5.png'
import './service.css'


export default function Service() {
  return (
  <center>
      <div className='container' >
        <div className='row align-items-center justify-content-between py-4' >
    <div className='col col-lg-4 col-sm-12 mt-3  ' >
<h1 style={{textAlign:"left",fontWeight:"bold",fontSize:"2.5rem"}}>Our Services</h1>
<p  style={{textAlign:"left",fontWeight:"bold",fontSize:"16px"}} className='mb-2'>We understand that every event is unique and we strive to accommodate your individual needs</p>
<img src={service1} style={{width:"400px",maxWidth:"95vw"}} className='mt-4' />
    </div>
    <div className='col col-lg-3 col-sm-12 mt-3 ' >
    <img src={service2} height={460} style={{width:"400px",maxWidth:"95vw"}} />
</div>
<div className='col col-lg-3 col-sm-12 mt-3 ' >
<img src={service4} width={300} className='service_image_3'  />
<img src={service5} width={300} className='service_image_3 mt-2' />


</div>
        </div>
    </div>
  </center>
  )
}
