import "./App.css";
import Router from "./router";
import { BrowserRouter } from "react-router-dom";
import { StateProvider } from "./context/StateProvider";
import { initialState } from "./context/initalState";
import reducer from "./context/reducer";
import { LoadScript } from "@react-google-maps/api";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga4";
function App() {
  ReactGA.initialize([
    {
      trackingId: "G-GBJQ6BKE0Q",
    },
    {
      trackingId: "AW-16606539740",
    },
  ]);
  return (
    <>
      <BrowserRouter>
        <StateProvider initialState={initialState} reducer={reducer}>
          <LoadScript
            googleMapsApiKey={"AIzaSyAWXS8dYOW3o6IzJFQuxe7b42BfPB_yi1c"}
            libraries={["places"]}
          >
            <Router />
          </LoadScript>
          <ToastContainer position="top-center" />
        </StateProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
