export const co2_calculation = (distance) => {
  // Calculate one-fourth of the distance
  const oneFourthDistance = distance * 0.243;

  // Fix the result to two decimal places
  const result = oneFourthDistance.toFixed(2);

  return result;
};

export const autocompleteOptions = {
  componentRestrictions: { country: "AE" },
};

export const initLocation = {
  lat: -33.86,
  lng: 151.12,
};

export const convertToAmPm = (time) => {
  const [hours, minutes] = time.split(":");
  let period = "AM";

  let hour = parseInt(hours);
  if (hour === 0) {
    hour = 12;
  } else if (hour === 12) {
    period = "PM";
  } else if (hour > 12) {
    hour -= 12;
    period = "PM";
  }

  return `${hour}:${minutes} ${period}`;
};

export const convertTo24HourFormat = (time) => {
  const [timeStr, period] = time.split(" ");
  const [hours, minutes] = timeStr.split(":");
  let hour = parseInt(hours);

  if (period === "PM" && hour !== 12) {
    hour += 12;
  } else if (period === "AM" && hour === 12) {
    hour = 0;
  }

  return `${hour.toString().padStart(2, "0")}:${minutes}`;
};

export const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + "...";
};

export const formatDateTime = (date, time) => {
  // console.log(date, time);

  // Extract day, month, and year from the date string
  const [day, month, year] = date.split("-");

  // Extract hours and minutes from the time string
  const [hours, minutes] = time.split(":");

  // Format the date and time in the desired format
  const formattedDateTime = `${year}-${month}-${day}T${String(hours).padStart(
    2,
    "0"
  )}:${String(minutes).padStart(2, "0")}:00`;

  return formattedDateTime;
};
