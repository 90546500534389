import React from 'react'
import './mission.css'
export default function Mission() {
    return (
        <>
       <div className='mission' >
       <div className='container '>
    
    <div className='row py-4'>
    <div className=' col-lg-6 col-sm-12'>
    
    <p style={{fontSize:"2.5rem",fontWeight:"bold",color:"white"}}>Our Mission</p>

    </div>
    <div className=' col-lg-6 col-sm-12'>
    <p style={{fontWeight:"bold",color:"white"}}>At Noor, our mission is to redefine urban mobility in Dubai by providing safe, affordable, and environmentally friendly transportation solutions. We are pioneering a sustainable future for city travel, making eco-friendly rides accessible to everyone, and leading by example in the shift towards greener, more sustainable urban environments.

    </p>
    </div>
    
    
    </div>
    <div className=' mission__m'>
    
    </div>
        </div>
       </div>
        </>
      )
}
