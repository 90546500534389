import React, { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert'; // Import SweetAlert
import './UpcomingRides.css'; // Import your CSS file
import leaf from '../../../assets/images/leaf.png';
import car from '../../../assets/images/car.png';
import person from '../../../assets/images/people.png';
import large from '../../../assets/images/large-bag.png';
import small from '../../../assets/images/small-bag.png';
import { toast } from 'react-toastify';
import { useStateValue } from '../../../context/StateProvider';
import { cancelBooking } from '../../../services/api';


function UpcomingRides({booking}) {
  const [showOptions, setShowOptions] = useState(false);
  const [{ token }] = useStateValue();
  const [showRideDetails, setShowRideDetails] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'Asia/Dubai',
  };

  
  const dateFormatter = (dateString) => {
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
    return formattedDate;
  };

  const roundToTwoDecimal = (number) => {
    return Math.round(number * 100) / 100;
  };

  const handleOptionsClick = () => {
    setShowOptions(!showOptions);
  };

  const handleRideDetailsClick = () => {
    setShowRideDetails(true); // Show the modal
    setShowOptions(false); // Hide options
  };

  const handleCancelRideClick = () => {
    // Implement the logic to cancel the ride
  };

  const handleReportIssueClick = () => {
    // Implement the logic to report an issue
  };

  const hideRideDetailsModal = () => {
    setShowRideDetails(false); // Hide the modal
  };
  const handleCancelBooking = async () => {
    setShowModal(true);
    setShowOptions(false); // Hide options
  };

  const handleConfirmCancel = async () => {
    try {
      const response = await cancelBooking({
        "reason":4,
        "customReason":"web cancel"
    }, token,booking.id);
      if (response.status === 200) {
        // Handle cancellation success
        toast.info("Booking cancelled successfully")
        window.location.reload()
        console.log('Booking cancelled successfully');
      } else {
        // Handle cancellation failure
        toast.error('Failed to cancel booking');
      }
    } catch (error) {
      // Handle cancellation failure
      toast.error('Failed to cancel booking');
    }
    setShowModal(false);
  };


  // const handleReschedule = () => {
  //   // Handle reschedule logic here

  // };

  const handleCancel = () => {
    setShowModal(false);
  };
  return (
    <div className="upcoming-ride-card">

      <div className="ride-info w-100 d-flex justify-content-between">
        <p>Booked on: <span>{booking && dateFormatter(booking?.requestedTimestamp)}</span> </p>
        <div className="options">
          <i className="bi bi-three-dots-vertical" onClick={handleOptionsClick}></i>
          {showOptions && (
            <div className="options-dropdown">
              <button onClick={handleRideDetailsClick}>Ride Details</button>
              <button onClick={handleCancelBooking}>Cancel Ride</button>
              {/* <button onClick={handleReportIssueClick}>Report Issue</button> */}
            </div>
          )}
        </div>
      </div>

      <SweetAlert
        show={showRideDetails}
        onCancel={hideRideDetailsModal}
        showCloseButton={true}
        showConfirm={false}
        closeOnClickOutside={true} // Close modal when clicking outside
      >
        <div className=' pt-0 pb-3 ps-3 pe-3'>
          <p className='detail-header'>Your Ride details</p>
          <div className="car-details  d-flex  align-items-center justify-content-between">
            <div className="details d-flex gap-3 align-items-center">
              <div className="car">
                <img src={car}  alt="" />
                <p>Tesla</p>
              </div>
              <div className="vertical-line"></div>
              <div className="breakdown d-flex flex-column justify-content-start align-items-start">
                <p className=' fs-6'><img src={person} alt="" /> 4 Persons</p>
                <p className=' fs-6'><img src={large} alt="" /> 2 Large Bags</p>
                <p className=' fs-6'><img src={small} alt="" /> 2 Small Bags</p>
              </div>
            </div>
            <div className="car-fare d-flex flex-column justify-content-start">
                <p className=' m-0 p-0'>Fixed Fare</p>
                <p className=' m-0'>AED {booking && booking.totalFare}</p>
              </div>
          </div>
          <p className='instruction'>Pay directly to the driver after the ride. All cards accepted</p>
        </div>
      </SweetAlert>

      <div className="location w-100  d-flex justify-content-start  align-items-center gap-4 pt-3">
      <div style={{justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"column",position:"absolute",height:"100%",left:"10px"}}>
    <div style={{border:"3px solid #49bc92",borderRadius:"50%",height:"10px",width:"10px"}}></div>
    <div style={{backgroundColor:"#49bc92",height:"50px",width:"1px"}}></div>
    <div  style={{border:"3px solid #49bc92",height:"10px",width:"10px"}}></div>
  </div>
        <div className="places" style={{marginLeft:"10px"}}>
          <p className=' m-0'>{booking?.request.startLocation}</p>
          <div className="horizontal-line"></div>
          <p className=' m-0'>{booking?.request.endLocation}</p>
        </div>
      </div>
      <div className="fare-details w-100  mt-4">
        <div className="fare-pickup">
          <p className=' m-0'>Pickup:</p>
          <p className=' m-0'>  {booking && dateFormatter(booking?.expectedStartTime)}</p>
        </div>
        <div className="fare mt-3">
          <p className=' m-0'>Fixed Fare:</p>
          <p className=' m-0'>AED {booking && booking.totalFare}</p>
        </div>
        <div className="fare mt-3">
          <p className=' m-0'>Pickup Note:</p>
          <p className=' m-0'>{booking && booking.pickNote}</p>
        </div>
      </div>
      <div className="co-info w-100 d-flex justify-content-center align-items-center p-3 gap-2 mt-4">
        <img src={leaf} alt="" />
        <p className=' m-0 fw-medium'>You will save {booking && roundToTwoDecimal(booking?.co2Saving)}kg Co2 with this ride</p>
      </div>
      <div className="about-ride w-100 mt-3">
        <p className=' m-0'>Ride details will be provided 30 mins before <br /> the ride. Pay directly to the driver.</p>
      </div>
      {showModal && (
  <div className="modal fade show" style={{ display: "block" }} tabIndex="-1">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
        <h5 className="modal-title">Confirm Cab Cancellation</h5>
          <button
            type="button"
            className="close"
            style={{width:"30px",border:"none"}}
            onClick={handleCancel}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
        <p className="text-gray-800">Are you sure you want to cancel this cab booking?</p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger"
            onClick={handleConfirmCancel}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCancel}
          >
            No
          </button>
        </div>
      </div>
    </div>
  </div>
)}
    </div>
  );
}

export default UpcomingRides;
