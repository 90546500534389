import React, { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert'; // Import SweetAlert
import './PastRides.css'; 
import car from '../../../assets/images/car.png';
import person from '../../../assets/images/people.png';
import large from '../../../assets/images/large-bag.png';
import small from '../../../assets/images/small-bag.png';



function PastRides({booking}) {

  const [showRideDetails, setShowRideDetails] = useState(false);

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'Asia/Dubai',
  };

  const dateFormatter = (dateString) => {
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
    return formattedDate;
  };

  const roundToTwoDecimal = (number) => {
    return Math.round(number * 100) / 100;
  };



  const hideRideDetailsModal = () => {
    setShowRideDetails(false); // Hide the modal
  };

  return (
    <div className="upcoming-ride-card">

      <div className="ride-info w-100 d-flex justify-content-between">
        <p>Booked on: <span>{booking && dateFormatter(booking?.requestedTimestamp)}</span> </p>
        {/* <div className="options">
          <i className="bi bi-three-dots-vertical" onClick={handleOptionsClick}></i>
          {showOptions && (
            <div className="options-dropdown">
              <button onClick={handleRideDetailsClick}>Ride Details</button>
              <button onClick={handleCancelRideClick}>Cancel Ride</button>
              <button onClick={handleReportIssueClick}>Report Issue</button>
            </div>
          )}
        </div> */}
      </div>

      <SweetAlert
        show={showRideDetails}
        onCancel={hideRideDetailsModal}
        showCloseButton={true}
        showConfirm={false}
        closeOnClickOutside={true} // Close modal when clicking outside
      >
        <div className=' pt-0 pb-3 ps-3 pe-3'>
          <p className='detail-header'>Your Ride details</p>
          <div className="car-details  d-flex  align-items-center justify-content-between">
            <div className="details d-flex gap-3 align-items-center">
              <div className="car">
                <img src={car} alt="" />
                <p>Tesla</p>
              </div>
              <div className="vertical-line"></div>
              <div className="breakdown d-flex flex-column justify-content-start align-items-start">
                <p><img src={person} alt="" /> 4 Persons</p>
                <p><img src={large} alt="" /> 2 Large Bags</p>
                <p><img src={small} alt="" /> 2 Small Bags</p>
              </div>
            </div>
            <div className="car-fare d-flex flex-column justify-content-start">
                <p className=' m-0 p-0'>Fixed Fare</p>
                <p className=' m-0'>AED {booking && booking.totalFare}</p>
              </div>
          </div>
          <p className='instruction'>Pay directly to the driver after the ride. All cards accepted</p>
        </div>
      </SweetAlert>

      <div className="location w-100  d-flex justify-content-start  align-items-center gap-4 pt-3">
      <div style={{justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"column",position:"absolute",height:"100%",left:"10px"}}>
    <div style={{border:"3px solid #49bc92",borderRadius:"50%",height:"10px",width:"10px"}}></div>
    <div style={{backgroundColor:"#49bc92",height:"50px",width:"1px"}}></div>
    <div  style={{border:"3px solid #49bc92",height:"10px",width:"10px"}}></div>
  </div>
        <div className="places" style={{marginLeft:"10px"}}>
          <p className=' m-0'>{booking?.request.startLocation}</p>
          <div className="horizontal-line"></div>
          <p className=' m-0'>{booking?.request.endLocation}</p>
        </div>
      </div>
      <div className="fare-details  w-100 mt-4">
        <div className="fare-pickup">
          <p className=' m-0'>Pickup:</p>
          <p className=' m-0'> {booking && dateFormatter(booking?.expectedStartTime)}</p>
        </div>
        <div className="fare mt-3">
          <p className=' m-0'>Paid:</p>
          <p className=' m-0'>AED {booking && roundToTwoDecimal(booking.totalFare)}</p>
        </div>
      </div>
      <div className="past-info w-100 d-flex justify-content-between align-items-center p-3 gap-2 mt-4">
        <p>Distance: {booking && roundToTwoDecimal(booking.request.distance)} KM</p>
        <div className="vertl"></div>
        <p>Time: {booking && roundToTwoDecimal(booking.request.time)} Mins</p>
        <div className="vertl"></div>
        <p>Co2 Saved: {booking && roundToTwoDecimal(booking?.co2Saving)} Kg</p>
      </div>
      <div className="receipt w-100 mt-3">
        <button onClick={()=>{
                   window.open(`https://noorride.com/receipt/${booking.id}`)

        }}>View Receipt </button>
      </div>
    </div>
  );
}

export default PastRides;
