import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import LocationCard from './LocationCard';
import { Autocomplete } from "@react-google-maps/api";
import Footer from '../Footer/Footer';
import './Landing.css'
import playstore from '../../assets/images/playstore.png';
import applestore from '../../assets/images/applestore.png';
import rowonex from '../../assets/images/bydback.jpg';
import rowtwox from '../../assets/images/landing_1.png';
import rowthreex from '../../assets/images/Driver.png';
import readytohit from '../../assets/images/readytohitx.png';
import CircularCarousel from './CarouselComponent';
import swap from '../../assets/images/swap.png';
import MobileCarousel from './MobileCarousel';
import qrcodex from '../../assets/images/qrcodex.png';
import flag from '../../assets/images/flag.png';
import { toast } from "react-toastify";
import Whatsappbtn from '../../components/layout/whatsapp'
import { serviceStatus } from "../../services/api";
import { useStateValue } from "../../context/StateProvider";
import va from "@vercel/analytics";
import ReactGA from "react-ga4";

function Landing() {
    const navigate = useNavigate();
      const [pickupInputValue, setPickupInputValue] = useState("");
      const [destInputValue, setDestInputValue] = useState("");
      const [{pickup,dest,service_id}, dispatch] = useStateValue();
      const [scrollToTop, setScrollToTop] = useState(false);
  
    const autocompleteRefPickup = useRef(null);
    const autocompleteRefDest = useRef(null);
    const pickupref = useRef("");
    const destref = useRef("");


    const [pickupPlace, setPickupPlace] = useState(null);
    const [destinationPlace, setDestinationPlace] = useState(null);

    const autocompleteOptions = {
      componentRestrictions: { country: "AE" },
      };
      const handleScrollToTop = () => {
        // Set the state variable to trigger smooth scroll
        setScrollToTop(true);
      };
    

    const handlePlaceSelectPickup = () => {
        if (autocompleteRefPickup.current !== null) {
          const place = autocompleteRefPickup.current.getPlace();
    
        //   setPickupLocation({
        //     lat: place.geometry.location.lat(),
        //     long: place.geometry.location.lng(),
        //   });
          setPickupInputValue(pickupref.current.value);
          // handleAutoSubmit()
    
    
    
        } else {
          console.log("Autocomplete for pickup is not loaded yet!");
        }
      };
    
      const handlePlaceSelectDest = () => {
        if (autocompleteRefDest.current !== null) {
          const place = autocompleteRefDest.current.getPlace();
    
        //   setDestLocation({
        //     lat: place.geometry.location.lat(),
        //     long: place.geometry.location.lng(),
        //   });
          
          setDestInputValue(destref.current.value);
          // handleAutoSubmit()
        } else {
          console.log("Autocomplete for destination is not loaded yet!");
        }
      };

    const handleLocationSwap = () => {
        // // Swap selected places
        // setPickupPlace(destinationPlace);
        // setDestinationPlace(pickupPlace);

        // // Swap input values
        // setPickupInputValue(destinationPlace?.formatted_address || '');
        // setDestinationInputValue(pickupPlace?.formatted_address || '');
    };
    const serviceable_text = () => (
      <div style={{fontSize:"14px",color:"black"}}>
       Not in our serviceable regions !<br/> <Link  to="/service"><span style={{fontSize:"14px",color:"black"}}><span className='text-primary'>Click here </span>to view serviceable regions</span></Link>
      </div>
    );
    const handleService = async () => {
      va.track("location_selected_landing");
      ReactGA.event({
        category: "booking",
        action: "choose_location",
      });
      const pickupPlace = autocompleteRefPickup.current.getPlace();
      const destPlace = autocompleteRefDest.current.getPlace();
      if (
        !pickupPlace ||
        !pickupref.current.value ||
        !destPlace ||
        !destref.current.value
      ) {
        toast.error("Select locations!");
        return;
      }
  
      let pickupLocation = {
        name: pickupref.current.value,
        ...{
          lat: pickupPlace.geometry.location.lat(),
          long: pickupPlace.geometry.location.lng(),
        }
      };
      let dropLocation = {
        name: destref.current.value,
        ...{
          lat: destPlace.geometry.location.lat(),
          long: destPlace.geometry.location.lng(),
        }
      };
      dispatch({ type: "SET_LOADING", isLoading: true });
  
      const response = await serviceStatus({ pickupLocation, dropLocation });
  
      if (response.status === 200 && response.data.status) {
    
        pickupLocation = {
          type:pickupPlace.types,
          ...pickupLocation
        }

      dropLocation = {
        type:destPlace.types,
        ...dropLocation
      }
  
        dispatch({ type: "SET_LOADING", isLoading: false });
        dispatch({ type: "SET_PICKUP", pickup: pickupLocation });
        dispatch({ type: "SET_DEST", dest:dropLocation });
        navigate("/book/slot");
      } else {
        va.track("location_notsvc");
        dispatch({ type: "SET_LOADING", isLoading: false });
  
        toast.info(
          serviceable_text,{
            autoClose:false
          }
        );
      }
    };

      useEffect(() => {
        if (scrollToTop) {
          // Scroll to the top of the page with smooth behavior
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          // Reset the scrollToTop state variable after scrolling
          setScrollToTop(false);
        }
      }, [scrollToTop]);
    
    return (
        <>
            <div className="headerx">
                <div className="headercontent d-flex flex-column align-items-center gap-5">


                    <LocationCard></LocationCard>

                </div>
            </div>
            <div className="headery ">
                <div className="headercontenty d-flex flex-column justify-content-end gap-0 ps-3 pb-3">
                    <p className="headercontenty-head">Cruise in style </p>
                    <p className="headercontenty-body">with <span className="GoBlu">Noor</span>-<span className="EV">Rides</span>.</p>
                </div>
                <div className="downloady gap-2 pt-4">
                    <div className="custom-input ">
                        <div className="icon">
                            <div className="borderone">
                                <div className="circleone"></div>
                            </div>
                        </div>
                        <Autocomplete
                        className="autocomplete"
                options={autocompleteOptions}
                onLoad={(autocomplete) => {
                  autocompleteRefPickup.current = autocomplete;
                  console.log("Autocomplete for pickup loaded:", autocomplete);
                }}
                onPlaceChanged={handlePlaceSelectPickup}
              >

<input
                            ref={pickupref}
                            type="text"
                            placeholder="Pickup Location"
                           
                            onChange={(e) => setPickupInputValue(e.target.value)}
                        />
              </Autocomplete >
                 
                    </div>
                    {/* <div className="swapy" onClick={handleLocationSwap}>
                        <img src={swap} alt="" />
                    </div> */}
                    <div className="custom-input">
                        <div className="icon">
                            <div className="bordertwo">
                                <div className="circletwo"></div>
                            </div>
                        </div>
                        <Autocomplete
                        className="autocomplete"
                options={autocompleteOptions}
                onLoad={(autocomplete) => {
                  autocompleteRefDest.current = autocomplete;
                  console.log(
                    "Autocomplete for destination loaded:",
                    autocomplete
                  );
                }}
                onPlaceChanged={handlePlaceSelectDest}
              >
                          <input
                            ref={destref}
                            type="text"
                            placeholder="Enter Destination"
                            
                          
                        />
              </Autocomplete>
              
                    </div>
           
                    <button onClick={
               handleService
            } className='checkfarey mt-3'>CHECK FARE</button>
                     <p className="checkfareins">
                     Anywhere to Anywhere in Dubai
                    </p>

                </div>
            </div>
            <div className="reviewx">
                <p>People are saying we're the best.</p>
                <p>Raving Reviews: Hear It Directly from Our Happy Customers!</p>

                <CircularCarousel></CircularCarousel>
            </div>
            <div className="whyx">
                <p>
                    Why Noor?
                </p>
            </div>
            <div className="ridex">
                <div className="rowonex">
                    <img src={rowonex} alt="" />
                    <div className="paraonex">
                        <p>Comfortable Rides</p>
                        <p>Enjoy our brand new noiseless and comfortable electric cars. You can relax and enjoy the ride, while our professional and courteous drivers ensure a delightful journey.</p>
                    </div>
                </div>
                <div className="rowtwox">
                    <div className="paratwox">
                        <p>Flat pricing, No surge</p>
                        <p>We believe sustainable rides should be economical along with being comfortable. We do not charge higher with rise in demand, but we always charge lesser than other options.</p>
                    </div>
                    <img src={rowtwox} alt="" />
                </div>
                <div className="rowthreex">
                    <img src={rowthreex} alt="" />
                    <div className="parathreex">
                        <p>Safety First</p>
                        <p>Our commitment to your well-being extends beyond our drivers to our vehicles themselves. All our drivers undergo rigorous background checks, and our vehicles are equipped with advanced safety features.</p>
                    </div>
                </div>
            </div>
            <div className="gogreenx">
                <div className="upperx">


                    <p className='gogogo'>Go Green, Go Electric, Go Comfortable</p>
                    <div className="statsx">
                        <p>Zero <br /> <span className='gospan'>Emissions</span> </p>
                        <p>
                            1000 <br />
                            <span className='gospan'>Kg CO2 Saved</span>
                        </p>
                        <p>100+ <br />
                            <span className="gospan">
                                Rides Completed
                            </span>
                        </p>
                    </div>
                </div>
                <div className="lowerx">
                    <p>
                        Every KM travelled by you using Noor saves around:
                    </p>
                    <p>
                        <span className="lowerspan">200gm</span>  of CO2!
                    </p>
                </div>
            </div>
            <div className="readytohit">
                <div className="readycontentx w-50">
                    <p>Ready to hit the road? </p>
                    <p className=' mt-3 mb-5'>Join us as a driver and become our valued partner!</p>
                  <Link to="/contact" style={{textDecoration:"none"}}>
                  <button>Become a driver</button></Link>
                </div>
                <div className="readyimagex w-50">
                    <img src={readytohit} alt="" />
                </div>
            </div>
            {/* <div className="appdownloadxy ">
                <div className="topscannerx">
                    <img src={qrcodex} alt="" />
                </div>
                <div className="mobilecarouselll">
                    <MobileCarousel></MobileCarousel>
                </div>
                <div className="bottomscannerx">
                    <p className=' mb-1 ms-5'>OR</p>
                    <div className="downloadlinkx ms-5">
                    
                        <div className=' w-50 d-flex gap-2 justify-content-center mt-2 flex-column' >
                            <p className=' text-center'>Enter your phone Number</p>
                            <div className=' d-flex justify-content-center align-items-center gap-2'>
                            <div className="countryx w-auto">
                                <img src={flag} height={25} className=' m-0 p-0' alt="" /><p className='  fw-medium m-0 p-0'>+61</p>
                            </div>
                            <input type="text" placeholder='0 491 570 156' />
                            </div>
                            
                        </div>
                        <div className="linkrecieve">
                            <p>Receive a download link </p>
                            <button>SEND</button>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="kickstartx">
                <p className=' text-center fs-4 fw-medium text-white'>
                    "Kick-start your eco-conscious journey with us – <br />
                    your first step towards greener rides."
                </p>
                <button onClick={handleScrollToTop}>
                    BOOK NOW
                </button>
            </div>
            <Whatsappbtn />
            <Footer/>
           
        </>
    );
}

export default Landing;