import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

const Serviceable_regions = () => {
  return (
    <Container className="my-5">
      <Row className="text-center mb-4">
        <Col>
          <h1>Cab Service</h1>
          <p className="lead">Pickup from anywhere in Dubai. Drop-off available in Dubai, Sharjah, Abu Dhabi, Ajman, Umm Al Quwain, Ras Al Khaimah, and Fujairah.</p>
          </Col>
      </Row>
     
    </Container>
  );
}

export default Serviceable_regions;


