import React from 'react'
import aboutfamily from '../../assets/images/aboutfamily.png'

export default function AboutFamily() {
  return (
    <div className='px-2'>
        <div className='row align-items-center justify-content-center py-5'>
        <div className='col col-lg-4 col-sm-12'>
<p style={{fontSize:"2.5rem",fontWeight:"bold"}}>Become a driver</p>
<h2>Ready to Noor Rides family?
</h2>
<p>Join Noor's growing team of drivers and experience the freedom and flexibility of being your own boss.
</p>
<a href="https://onelink.to/wyccdc" target='_blank' className="btn text-white d-flex justify-content-center  align-items-center book mt-4"  style={{maxWidth:"470px",width:"100%",color:"white"}}>DOWNLOAD NOW</a>

</div>
<div className='col col-lg-6 col-sm-12'>
<center>
<img src={aboutfamily} height={350} width={500} className='aboutserviceimage'/>
</center>
</div>

        </div>
    </div>
  )
}
