import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import faqImage from '../../assets/images/faqImage.png';
import './FAQPage.css';
import { toast } from 'react-toastify';
import { contactUs } from '../../services/api';
import Footer from '../Footer/Footer';

function FAQPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: '',
    inquiryType: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: '',
    inquiryType: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    // Validate the individual input field on change
    const newErrors = { ...errors };
    if (!value.trim() && name !== 'phoneNumber') {
      newErrors[name] = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
    } else {
      newErrors[name] = '';
    }
    setErrors(newErrors);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate all fields before submitting
    const newErrors = {};
    for (const [key, value] of Object.entries(formData)) {
      if (!value.trim() && key !== 'phoneNumber') {
        newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
      }
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Proceed with form submission
      try {
        const response = await contactUs(formData);

        if (response.data.status === 'success') {
          toast.info('We will get back to you soon!');
          setFormData({
            name: '',
            email: '',
            phoneNumber:"",
            inquiryType: '',
            message: '',
          });
        } else {
          toast.error('Failed to submit!');
        }

        console.log('Form submitted:', formData);
      } catch (error) {
        console.error('API Request Error:', error);
        toast.error('Failed to submit!');
      }
    }
  };

  return (
    <div className="faq m-0 w-100">
      <div className=" d-flex header m-0 rounded-0">
        <div className="col-md-6 d-flex ">
          <div>
            <h2 className="text-white fs-1">Get in Touch!</h2>
            <p className="text-white">Have Questions or Need Assistance? We're Here for You!</p>
          </div>
        </div>
        <div className="col-md-6 d-flex justify-content-center align-items-center">
          <img src={faqImage} alt="FAQ" className="img-fluid" />
        </div>
      </div>
      <div className="content-container d-flex mt-5 me-0 ms-0">
        <div className="form-container col-md-6 d-flex flex-column">
          <div className="content">
            <h4 className="text-start">Fill out the form and we will <br /> reach you soon! </h4>
            <form className="">
              <div className={`form-group ${errors.name ? 'has-error' : ''}`}>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                  id="name"
                  name="name"
                  placeholder="Enter your name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
                {errors.name && <div className="invalid-feedback">{errors.name}</div>}
              </div>

              <div className={`form-group ${errors.email ? 'has-error' : ''}`}>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
              </div>

              <div className="form-group">
                <label htmlFor="phoneNumber">Phone Number</label>
                <input
                  type="tel"
                  className="form-control"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Enter your phone number"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
              </div>

              <div className={`form-group ${errors.inquiryType ? 'has-error' : ''}`}>
                <label htmlFor="inquiryType">Inquiry Type</label>
                <select
                  className={`form-control ${errors.inquiryType ? 'is-invalid' : ''}`}
                  id="inquiryType"
                  name="inquiryType"
                  value={formData.inquiryType}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select an option</option>
                  <option value="rider">Rider</option>
                  <option value="driver">Driver</option>
                  <option value="business">Business Inquiry</option>
                  <option value="other">Other</option>
                </select>
                {errors.inquiryType && <div className="invalid-feedback">{errors.inquiryType}</div>}
              </div>

              <div className={`form-group ${errors.message ? 'has-error' : ''}`}>
                <label htmlFor="message">Message</label>
                <textarea
                  className={`form-control ${errors.message ? 'is-invalid' : ''}`}
                  id="message"
                  name="message"
                  rows="4"
                  placeholder="Enter your message"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                />
                {errors.message && <div className="invalid-feedback">{errors.message}</div>}
              </div>
              <br />
              <button type="submit" className="btn" onClick={handleSubmit}>
                Submit
              </button>
            </form>
          </div>

          <div className="info">
            <h4>Easily contact us for quick customer support!</h4>
            <ul>
              <li><i className="bi bi-telephone-fill"></i> +97148718604</li>
              {/* <li><i className="bi bi-whatsapp"></i> +971 1234567898</li> */}
              <li><i className="bi bi-envelope-fill"></i>info@noorride.com</li>
            </ul>
          </div>
        </div>
        <div className="faq-container col-md-6">
          <div className="faq">
            <h4>Frequently Asked Questions</h4>
            <div class="accordion accordion-flush" id="accordionFlushExample">
    
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                  What types of cabs do you offer? 
                  </button>
                </h2>
                <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                  Currently we serve with a fleet of Tesla Model Y </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                  What payment methods do you accept? 
                  </button>
                </h2>
                <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                  Any mode of payment is acceptable. However, card payments are highly encouraged                </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button  collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                  Do you provide intercity commute?
                  </button>
                </h2>
                <div id="flush-collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                  Yes, we provide services from anywhere to anywhere in Dubai.             </div>
              </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button  collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                  Are there any hidden charges in the fare? 
                  </button>
                </h2>
                <div id="flush-collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                  No, there are no hidden charges we offer a fixed fare.               </div>
              </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button  collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseeight" aria-expanded="false" aria-controls="flush-collapseeight">
               
How far in advance can I book a cab? 
                  </button>
                </h2>
                <div id="flush-collapseeight" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                  You can book a cab 60 days in advance.              </div>
              </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button  collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseseven" aria-expanded="false" aria-controls="flush-collapseseven">
               

                  How many bags can I take with me?
                  </button>
                </h2>
                <div id="flush-collapseseven" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                  You can take 2 big bags and 2 small bags with you.              </div>
              </div>
            </div>
          </div>

</div>

        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default FAQPage;
