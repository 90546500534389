
import React , {Component} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Review =() =>{
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        // centerMode: true,
        // className: "center",
        slidesToScroll: 1,
        autoplay:true, responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]

      };
    return(
        <>
        <Slider {...settings}>
     <div>
     <div className="bg-white lg:w-[400px] lg:h-[350px]  px-3 py-4 rounded text-black space-y-6 my-5 mx-3">
            <div className="">     
                   <p> 
                   Great experience and would reccomend to anyone. Friendly driver was on time, calling 5 minutes early to confirm which pickup zone we were in.
                    </p> 
                
            </div>   
            <div className="flex items-center justify-start space-x-5">
                {/* <img src="https://goblu-assets.s3.ap-southeast-2.amazonaws.com/dummy.jpg" height={70} width={70}></img> */}
            <div className="flex-col items-center gap-[10px] mt-3">
                <text className="font-bold"><b>Shahjad</b></text>
             
                </div>

            </div>

        </div>
     </div>
     <div>
     <div className="bg-white lg:w-[400px] lg:h-[350px]  px-3 py-4 rounded text-black space-y-6 my-5 mx-3">
            <div className="">     
                   <p> 
                   Perfect service. Very nice polite driver arrived on time (even a bit early) and drove us quietly to destination. Could not demand more. Recommend this ecologic transport in Dubai.
                    </p> 
                
            </div>   
            <div className="flex items-center justify-start space-x-5">
                {/* <img src="https://goblu-assets.s3.ap-southeast-2.amazonaws.com/dummy.jpg" height={70} width={70}></img> */}
            <div className="flex-col items-center gap-[10px] mt-3">
                <text className="font-bold"> <b>Wajeeh</b></text>
             
                </div>

            </div>

        </div>
     </div>
     <div>
     <div className="bg-white lg:w-[400px] lg:h-[350px]  px-3 py-4 rounded text-black space-y-6 my-5 mx-3">
            <div className="">     
                   <p> 
                   Just tried the Noor Ride cab service...and I like it! It's much more economical than similar competing services.
                    </p> 
                
            </div>   
            <div className="flex items-center justify-start space-x-5">
                {/* <img src="https://goblu-assets.s3.ap-southeast-2.amazonaws.com/dummy.jpg" height={70} width={70}></img> */}
            <div className="flex-col items-center gap-[10px] mt-3">
                <text className="font-bold"> <b>Faizan</b></text>
             
                </div>

            </div>

        </div>
     </div>
     <div>
     <div className="bg-white lg:w-[400px] lg:h-[350px]  px-3 py-4 rounded text-black space-y-6 my-5 mx-3">
            <div className="">     
                   <p> 
                        Booking a Noor cab for my family's ride to Dubai airport was a seamless experience.
                        The entire journey was smooth, and we were impressed with the service provided. 
                        
                    </p> 
                
            </div>   
            <div className="flex items-center justify-start space-x-5">
                {/* <img src="https://goblu-assets.s3.ap-southeast-2.amazonaws.com/dummy.jpg" height={70} width={70}></img> */}
            <div className="flex-col items-center gap-[10px] mt-3 ">
                <text className="font-bold "> <b>Fatima</b></text>
             
                </div>

            </div>

        </div>
     </div>
    </Slider>
        </>
     
        
    );
}
export default Review;
