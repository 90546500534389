import React, { useState , useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import logo from '../../assets/images/integratedlogo.png';
import icon from '../../assets/images/icon.png';
import "./Navbar.css"
import AuthModal from '../Booking Flow/Verify/auth/authModal';
import ProfileModal from '../Booking Flow/Verify/auth/profileModal';
import { useStateValue } from '../../context/StateProvider';
import { getUser } from '../../services/api';
import { useNavigate } from "react-router-dom";
import {GiHamburgerMenu} from 'react-icons/gi'

function Navbar({ isLoggedIn }) {
  const [{ token, service_id, pickup, dest, polyline }, dispatch] =
  useStateValue();
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
const [isOpen, setIsOpen] = useState(false);

const navbarRef = useRef(null);

useEffect(() => {
  function handleClickOutside(event) {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      // Clicked outside the navbar, close the menu if it's open
      if (isOpen) {
        setIsOpen(false);
        // Collapse the Bootstrap navbar by toggling the collapse class
        const navbarCollapse = document.querySelector('.navbar-collapse');
        if (navbarCollapse) {
          navbarCollapse.classList.remove('show');
        }
      }
    }
  }
  const handleScroll = () => {
    if (navbarRef.current) {
      // Check if the window scrollY is greater than the navbar's bottom position
      const navbarBottom = navbarRef.current.getBoundingClientRect().bottom;
      if (window.scrollY > navbarBottom) {
        setIsOpen(false);
         // Collapse the Bootstrap navbar by toggling the collapse class
         const navbarCollapse = document.querySelector('.navbar-collapse');
         if (navbarCollapse) {
           navbarCollapse.classList.remove('show');
         }
      }
    }
  };

  // Attach the event listener when the component mounts
  document.addEventListener('click', handleClickOutside);
  window.addEventListener('scroll', handleScroll);
  

  // Cleanup by removing the event listener when the component unmounts
  return () => {
    document.removeEventListener('click', handleClickOutside);
    window.removeEventListener('scroll', handleScroll);

  };
}, [isOpen]);
const closeNavbar = () => {
  if (isOpen) {
    setIsOpen(false);
    // Collapse the Bootstrap navbar by toggling the collapse class
    const navbarCollapse = document.querySelector('.navbar-collapse');
    if (navbarCollapse) {
      navbarCollapse.classList.remove('show');
    }
  }
};
const toggleNavbar = () => {
  setIsOpen(!isOpen);
};

const navigate = useNavigate();
// modal open auth modal
const openAuthModal = () => {
  setIsAuthModalOpen(true);
};

const closeAuthModal = () => {
  setIsAuthModalOpen(false);
};

// modal open profile

const openProfileModal = () => {
  setIsProfileModalOpen(true);
};

const closeProfileModal = () => {
  setIsProfileModalOpen(false);
};


const book = ()=>{
  navigate("/book");
}

const handleLogin = async () => {

  try {
    const response = await getUser("", localStorage.getItem("token"));
    if (response.status === 200) {
      if (response.data.isProfileCompleted) {
        await book();
      } else {
        setIsProfileModalOpen(true);
      }
    } else {
      setIsAuthModalOpen(true);
    }
  } catch {
    setIsAuthModalOpen(true);
  }
};
  const logout = ()=>{
    localStorage.clear()
    window.location.href = '/';

    
  }
  return (
    <>
    <nav  ref={navbarRef} className="navbar navbar-light navbar-expand-lg "  style={{ backgroundColor: '#F6F6F6'}}   >
      <div className="container-fluid px-lg-5">
        <Link className="navbar-brand" to="/"> 
          <img src={logo} alt="" width="auto" height="50" />
        </Link>
        <button  onClick={toggleNavbar} style={{ width: "50px" , border:"none",outline:"none"}} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
<GiHamburgerMenu style={{color:"black"}}/>
        </button>
        <div  className="collapse navbar-collapse justify-content-between" id="navbarNav">
          <ul className="navbar-nav">
          <li onClick={closeNavbar}  className="nav-item ms-4">
              <Link className="nav-link" to="/about">About Us</Link> 
            </li>
            <li  onClick={closeNavbar}  className="nav-item ms-4">
              <Link  className="nav-link" to="/contact">Contact Us</Link> 
            </li>
        
          </ul>
 
          <ul className="navbar-nav ml-auto">
            {isLoggedIn ? ( // Conditional rendering for logged-in state
              <>
                     <li onClick={closeNavbar}  className="nav-item ms-4">
                  <Link className="nav-link" to="/">Book Now</Link> 
                </li>
                <li onClick={closeNavbar} className="nav-item ms-4">
                  <Link className="nav-link" to="/book/rides">Dashboard</Link> {/* Show Dashboard when logged in */}
                </li>
                <li className="nav-item ms-4">
                  <Link     onClick={logout} className="nav-link" to="/">Logout</Link> 
                </li>
              </>
            ) : (
              <>
                {/* <li className="nav-item ms-4">
                  <Link className="nav-link" to="/driver">Become a driver</Link> 
                </li> */}
                <li onClick={closeNavbar}  className="nav-item ms-4">
                  <Link className="nav-link" to="/">Book Now</Link> 
                </li>
                <li onClick={closeNavbar}  className="nav-item ms-4">
                  <Link className="nav-link" onClick={handleLogin}>Login</Link> 
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
          {/*auth  modal */}
          {isAuthModalOpen && (
        <AuthModal
          closeModal={closeAuthModal}
          dispatch={dispatch}
          token={token}
          book={book}
          profileModalOpen={openProfileModal}
        />
      )}

      {/*profile  modal */}
      {isProfileModalOpen && (
        <ProfileModal
          closeModal={closeProfileModal}
          dispatch={dispatch}
          token={token}
          book={book}
        />
      )}
    </>
  );
}

export default Navbar;
