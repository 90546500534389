import React from 'react';

export default function FareModal({ priceTable, closeModal }) {
  return (
    <div style={modalStyle}>
      <div style={containerStyle}>
        <h2 style={modalHeaderStyle}>Fare Breakdown</h2>

        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>Base Fare ({priceTable.distance.toFixed(2)} km)</td>
              <td className="text-right">AED {priceTable.baseFare.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Salik</td>
              <td className="text-right">AED {priceTable.salik}</td>
            </tr>
            <tr>
              <td>Waiting Charge</td>
              <td className="text-right">AED {priceTable.waitingCharge.toFixed(2)}</td>
            </tr>
            <tr>
              <td className="border-bottom">Distance Charge</td>
              <td className="border-bottom text-right">AED {priceTable.distanceCharge.toFixed(2)}</td>
            </tr>
            {/* <tr>
              <td className="border-bottom mt-3">Sub Total</td>
              <td className="border-bottom text-right">
                AED {priceTable.subTotal.toFixed(2)}
              </td>
            </tr>*/}
            <tr>
              <td className="border-bottom mt-3">Surcharge @2%</td>
              <td className="border-bottom text-right">
                AED {priceTable.surcharge.toFixed(2)}
              </td>
            </tr> 
            <tr>
              <td className="border-bottom font-weight-bold mt-3">Total Fare</td>
              <td className="border-bottom text-right font-weight-bold">
                AED {priceTable.totalFare.toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>

        <div style={modalContentStyle}>
          <h3 className="font-weight-bold">Terms and Conditions:</h3>
          <ul className="list-unstyled">
            <li>Fare is inclusive of fees, tolls, and taxes</li>
         
          </ul>
        </div>

        <button
        style={{  background: 'linear-gradient(to right, #49BC92, #49BC92)'}}
          className="btn btn-primary"
          onClick={closeModal}
        >
          Close
        </button>
      </div>
    </div>
  );
}

const modalStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.75)',
};

const containerStyle = {
  background: 'white',
  maxWidth: '460px',
  padding: '2rem',
  borderRadius: '0.5rem',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  position: 'relative',
};

const modalHeaderStyle = {
  fontSize: '1.5rem',
  fontWeight: 'bold',
  marginBottom: '1rem',
};

const modalContentStyle = {
  marginTop: '1rem',
  textAlign: 'left',
};
