import React from 'react'
import test_header  from '../../assets/images/test_header.png'
import Review from './review'
export default function Testimonial() {
  return (
    <div style={{backgroundColor:"black",position:"relative"}}  className='pt-4'>
        {/* header */}
      <center>
      <div style={{backgroundColor:"#49BC92",width:"95%"}} className=' row rounded text-white'>

<div className='col col-lg-4  '>
    <img src={test_header}/>
</div>
<div className='col col-lg-8  row align-items-center ' style={{textAlign:"left"}}>
<h3 className='col col-lg-6 col-12' >Hear It Directly from Our Customers!
</h3>
<div className='col col-lg-6 col-12'>
{/* <p >Best deals and services </p> */}
</div>
</div>
        </div>

      </center>

        {/* reviews */}
<center>
<div style={{maxWidth:"85%",width:"100%"}}>    
<Review/>  
</div>
  
</center>
    </div>
  )
}
