import React from 'react'
import whynoor from '../../assets/images/whynoor.png'
import whynoor_m from '../../assets/images/whynoor_m.png'
import rowonex from '../../assets/images/rowonex.png';
import rowtwox from '../../assets/images/rowtwox.png';
import rowthreex from '../../assets/images/rowthreex.png';
import './whynoor.css'
export default function Whynoor() {
  return (
    <div style={{backgroundColor:"black" }} className='py-4' >
<center>
<div>
{/* iamge  */}
<img src={whynoor} className='whynoor' />
<img src={whynoor_m} className='whynoor_m' />

        </div>

        {/* from goblu */}
        <div className="ridex">
                <div className="rowonex">
                    <img src={rowonex} alt="" />
                    <div className="paraonex">
                        <p>Comfortable Rides</p>
                        <p>Enjoy our brand new noiseless and comfortable electric cars. You can relax and enjoy the ride, while our professional and courteous drivers ensure a delightful journey.</p>
                    </div>
                </div>
                <div className="rowtwox">
                    <div className="paratwox">
                        <p>Flat pricing, No surge</p>
                        <p>We believe sustainable rides should be economical along with being comfortable. We do not charge higher with rise in demand, but we always charge lesser than other options.</p>
                    </div>
                    <img src={rowtwox} alt="" />
                </div>
                <div className="rowthreex">
                    <img src={rowthreex} alt="" />
                    <div className="parathreex">
                        <p>Safety First</p>
                        <p>Our commitment to your well-being extends beyond our drivers to our vehicles themselves. All our drivers undergo rigorous background checks, and our vehicles are equipped with advanced safety features.</p>
                    </div>
                </div>
            </div>
</center>
    </div>
  )
}
