import React from 'react'
import box4 from '../../assets/images/box4.png'
import carf from '../../assets/images/carf.png'
import { Link } from 'react-router-dom'

export default function Everykm() {
  return (
    <div style={{backgroundColor:"white"}}>
      <center>
      <div className='row container px-4 align-items-center'>

<div className='col col-lg-6 col-sm-12'>
<h1 style={{fontSize:"2.5rem",textAlign:"left"}} className='everykm__text'>
    <b>
    Every KM travelled by you using Noor saves around:
200gm of CO2!
    </b>
</h1>
</div>

<div className='col col-lg-6 col-sm-12 ' style={{ display: 'flex', justifyContent: 'center' }}>


<img src={box4} className='everykm_img' style={{scale:"0.8"}}/>

</div>
        </div>
      </center>

      <center>
      <div className='row container px-4 align-items-center mt-5'>

      <div className='col col-lg-6 col-sm-12  order-2 order-lg-1' style={{ display: 'flex', justifyContent: 'center' }}>
    <img src={carf} className='everykm_img' style={{ transform: "scale(0.8)" }} />
</div>


<div className='col col-lg-6 col-sm-12 d-flex flex-column justify-content-left order-1 order-lg-2'>
<h1 style={{fontSize:"2.5rem",textAlign:"left"}}  className='everykm__text'>
    <b>
    Schedule your rides around Dubai
    </b>
</h1>
<p style={{textAlign:"left"}}>We understand that every event is unique and we strive to accommodate your individual needs</p>
    {/* <button  className='book mt-4' style={{maxWidth:"470px",width:"100%"}}>CHECK FARE</button> */}
    <Link to="/book"  className="btn text-white d-flex justify-content-center  align-items-center book mt-4"  style={{maxWidth:"470px",width:"100%",color:"white"}}>CHECK FARE</Link>

</div>
        </div>
      </center>
    </div>
  )
}
