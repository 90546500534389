import React from 'react'
import explore from '../../assets/images/explore.svg'
export default function Explore() {
  return (
    <div className='px-2 py-5 justify-content-center d-flex' style={{backgroundColor:"black"}}>
        <div className='row justify-content-center'>

<div className='col col-lg-4 col-sm-12 order-2 order-lg-1 explore__hand ' >

<img src={explore} className='explore__m'/>
</div>
<div className='col col-lg-4 col-sm-12 order-1 order-lg-2' style={{color:"white !important"}}>
<p style={{fontSize:"2.5rem",fontWeight:"bold",color:"white"}}>Explore Dubai your way, with Noor Rides.
</p>
<h2 style={{color:"white"}}>Ready to hit the road?
</h2>
<p style={{color:"white"}}>Visit our website or download our app to browse our fleet and book your rental today!
</p>
<a href="https://onelink.to/wyccdc" target='_blank' className="btn text-white d-flex justify-content-center  align-items-center book mt-4"  style={{maxWidth:"470px",width:"100%",color:"white"}}>DOWNLOAD NOW</a>
</div>
        </div>
    </div>
  )
}
