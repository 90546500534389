import React from 'react'
import aboutserviceimage from '../../assets/images/aboutservice.png'
export default function Aboutservice() {
  return (
    <div className='py-5 px-2' style={{backgroundColor:"black",color:"white",display:"flex",justifyContent:"center"}}>
        <div className='row align-items-center justify-content-center'>
             <div className='col col-lg-4 col-sm-12 my-4'>

<h2 className='mb-2'><b>Exceptional Service, Every Ride</b>
</h2>
<p>
At Noor, ensuring customer satisfaction from booking to destination is key. Our goal is to offer a seamless, enjoyable ride with courteous drivers, modern vehicles, and an easy-to-use platform, making every Noor Dubai experience memorable.
</p>
             </div>
             <div className='col col-lg-6 col-sm-12 '>
<center>
<img src={aboutserviceimage} className='aboutserviceimage' height={250} width={350}/>
</center>
             </div>
        </div>
    </div>
  )
}
