import React from 'react'
import aboutdriver from '../../assets/images/aboutdriver.png'

export default function AboutDriver() {
  return (
    <div className='px-2'>
        <div className='row align-items-center justify-content-center py-5'>
<div className='col col-lg-6 col-sm-12'>
<center>
<img src={aboutdriver} height={300} width={400} className='aboutserviceimage'/>
</center>
</div>
<div className='col col-lg-6 col-sm-12'>
<p style={{fontSize:"2.5rem",fontWeight:"bold"}}>Join Us in<br/> Driving Change</p>
<p>We invite you to join us on our journey towards a more sustainable future. Whether you're a resident of Dubai or a visitor to the city, we encourage you to choose Noor Dubai for your transportation needs and help us drive positive change one ride at a time.
</p>
<a href="https://onelink.to/wyccdc" target='_blank' className="btn text-white d-flex justify-content-center  align-items-center book mt-4"  style={{maxWidth:"470px",width:"100%",color:"white"}}>DOWNLOAD NOW</a>
</div>
        </div>
    </div>
  )
}
