import React, { useState, useEffect } from 'react';
import { Card, ListGroup, Button } from 'react-bootstrap';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { fetchPayment, removePayment } from '../../../services/api';
import { useStateValue } from '../../../context/StateProvider';
import { toast } from 'react-toastify';
import AddCardModal from '../../Booking Flow/Verify/auth/card';


const ProfilePage = () => {
    const [{ token }, dispatch] = useStateValue();
    const [addCardModalOpen, setAddCardModalOpen] = useState(false);
//modal card open
const openAddCardModal = () => {
  setAddCardModalOpen(true);
};

const closeAddCardModal = () => {
  setAddCardModalOpen(false);
};

const book = ()=>{
closeAddCardModal();
window.location.reload()

}

  const [userData, setUserData] = useState({
    name: '',
    phoneNumber: '',
    email:''
  });

  const [paymentMethods, setPaymentMethods] = useState([]);

  
  const fetchPaymentData = async()=>{
    const response = await fetchPayment(token);

    setPaymentMethods(response.data.methods)
    setUserData({
        name:response.data.name,
        email:response.data.email,
        phoneNumber:response.data.number
    })
  }
  useEffect(() => {
   
    
fetchPaymentData()
   
  }, []);

  const handleRemovePayment = async (id) => {
    // Filter out the payment method with the specified id
    const response = await removePayment(token,id);
    window.location.reload()
    if(response.status===200){
        // window.location.reload()
    }else{
        toast.info("Error occured")
    }

  };

  return (
    <div className="container mt-4">
      <Card>
        <Card.Header as="h5">Basic Information</Card.Header>
        <Card.Body>
          <ListGroup variant="flush">
            <ListGroup.Item>Name: {userData.name}</ListGroup.Item>
            <ListGroup.Item>Phone Number: {userData.phoneNumber}</ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>

      {/* <Card className="mt-4">
        <Card.Header as="h5">Payment Methods</Card.Header>
        <Card.Body>
          <ListGroup variant="flush">
            {paymentMethods.map((method) => (
              <ListGroup.Item key={method.id}>
                <strong>Card Type: Credit Card</strong>
                <br />
                Last 4 Digits: **** **** **** {method.card.last4}
                <br />
                Expiration: {method.card.exp_month}/{method.card.exp_year}
                <Button
                  variant="danger"
                  className="float-right ms-2"  
                  onClick={async()=>{
                  await handleRemovePayment(method.id)
                  }}
                >
                  <FaTrash /> Remove
                </Button>
              </ListGroup.Item>
            ))}
     
            {paymentMethods.length === 0 && (
              <ListGroup.Item>
              
               <Button variant="success" onClick={openAddCardModal}>
                  <FaPlus /> Add Payment Method
                </Button>
             
              </ListGroup.Item>
            )}
          </ListGroup>
        </Card.Body>
      </Card> */}

        {/* add card modal */}
        {addCardModalOpen && (
        <AddCardModal
          closeModal={closeAddCardModal}
          dispatch={dispatch}
          token={token}
          book={book}
          isBooking={true}
        />
      )}
    </div>
  );
};

export default ProfilePage;
