import React, { useState, useRef, useEffect } from 'react';
import herobg from '../../assets/images/herobg.png';
import heroinn from '../../assets/images/heroinn.png';
import co2 from '../../assets/images/co2.png';
import playstore from '../../assets/images/playstore.png';
import applestore from '../../assets/images/applestore.png';

import { Link, useNavigate } from "react-router-dom";
import { Autocomplete } from "@react-google-maps/api";
import { toast } from "react-toastify";
import { serviceStatus } from "../../services/api";
import { useStateValue } from "../../context/StateProvider";
import va from "@vercel/analytics";

import ReactGA from "react-ga4";

export default function Hero() {
  
  const [pickupInputValue, setPickupInputValue] = useState("");
  const [destInputValue, setDestInputValue] = useState("");
  const [{pickup,dest,service_id}, dispatch] = useStateValue();
  const navigate = useNavigate();

const autocompleteRefPickup = useRef(null);
const autocompleteRefDest = useRef(null);
const pickupref = useRef("");
const destref = useRef("");


const [pickupPlace, setPickupPlace] = useState(null);
const [destinationPlace, setDestinationPlace] = useState(null);

const autocompleteOptions = {
  componentRestrictions: { country: "AE" },
  };


const handlePlaceSelectPickup = () => {
    if (autocompleteRefPickup.current !== null) {
      const place = autocompleteRefPickup.current.getPlace();
      setPickupInputValue(pickupref.current.value);

    } else {
      console.log("Autocomplete for pickup is not loaded yet!");
    }
  };

  const handlePlaceSelectDest = () => {
    if (autocompleteRefDest.current !== null) {
      const place = autocompleteRefDest.current.getPlace();

      
      setDestInputValue(destref.current.value);
      // handleAutoSubmit()
    } else {
      console.log("Autocomplete for destination is not loaded yet!");
    }
  };




const serviceable_text = () => (
  <div style={{fontSize:"14px",color:"black"}}>
   Not in our serviceable regions !<br/> <Link  to="/service"><span style={{fontSize:"14px",color:"black"}}><span className='text-primary'>Click here </span>to view serviceable regions</span></Link>
  </div>
);

const handleService = async () => {
  va.track("location_selected_landing");
  ReactGA.event({
    category: "booking",
    action: "choose_location",
  });
  const pickupPlace = autocompleteRefPickup.current.getPlace();
  const destPlace = autocompleteRefDest.current.getPlace();
  if (
    !pickupPlace ||
    !pickupref.current.value ||
    !destPlace ||
    !destref.current.value
  ) {
    toast.error("Select locations!");
    return;
  }

  let pickupLocation = {
    name: pickupref.current.value,
    ...{
      lat: pickupPlace.geometry.location.lat(),
      long: pickupPlace.geometry.location.lng(),
    }
  };
  let dropLocation = {
    name: destref.current.value,
    ...{
      lat: destPlace.geometry.location.lat(),
      long: destPlace.geometry.location.lng(),
    }
  };
  dispatch({ type: "SET_LOADING", isLoading: true });

  const response = await serviceStatus({ pickupLocation, dropLocation });

  if (response.status === 200 && response.data.status) {

    pickupLocation = {
      type:pickupPlace.types,
      ...pickupLocation
    }

  dropLocation = {
    type:destPlace.types,
    ...dropLocation
  }

    dispatch({ type: "SET_LOADING", isLoading: false });
    dispatch({ type: "SET_PICKUP", pickup: pickupLocation });
    dispatch({ type: "SET_DEST", dest:dropLocation });
    navigate("/book/slot");
  } else {
    va.track("location_notsvc");
    dispatch({ type: "SET_LOADING", isLoading: false });

    toast.info(
      serviceable_text,{
        autoClose:false
      }
    );
  }
};
  return (
    <>
<div id="book" className='hero__outer' style={{minHeight: "calc(100vh - 68px)"}}>
    <div className='row  hero__mobile    '>
        <div className='col col-lg-5 col-sm-10 container__1 mt-5'>
       <h4 >Cruise in style around Dubai</h4>
       <h1><b>Go <span style={{fontSize:"2.5rem"}}>electric </span> , <br/> 
go easy on your wallet!</b></h1>

<div style={{position:"relative" ,marginTop:"4rem"}}>
  <div style={{justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"column",position:"absolute",height:"100%",left:"10px"}}>
    <div style={{border:"3px solid #49bc92",borderRadius:"50%",height:"10px",width:"10px"}}></div>
    <div style={{backgroundColor:"#49bc92",height:"60px",width:"1px"}}></div>
    <div  style={{border:"3px solid #49bc92",height:"10px",width:"10px"}}></div>
  </div>
    <div>
        <Autocomplete
            options={autocompleteOptions}
            onLoad={(autocomplete) => {
                autocompleteRefPickup.current = autocomplete;
                console.log("Autocomplete for pickup loaded:", autocomplete);
            }}
            onPlaceChanged={handlePlaceSelectPickup}
        >
            <input
                style={{ backgroundColor: "#E8E8E8", maxWidth: "470px" }}
                type="text"
                ref={pickupref}
                placeholder="Enter Location"
                className="form-control  h-5vh text-black  px-4 py-3 rounded-lg"
            />
        </Autocomplete>
    </div>
    <div>
        <Autocomplete
            options={autocompleteOptions}
            onLoad={(autocomplete) => {
                autocompleteRefDest.current = autocomplete;
                console.log("Autocomplete for destination loaded:", autocomplete);
            }}
            onPlaceChanged={handlePlaceSelectDest}
        >
            <input
                type="text"
                ref={destref}
                style={{ backgroundColor: "#E8E8E8", maxWidth: "470px" }}
                placeholder="Enter Destination"
                className="form-control  h-5vh mt-3 text-black  px-4 py-3 rounded-lg"
            />
        </Autocomplete>
    </div>
</div>

<div>
    <button onClick={
               handleService
            } className='book mt-4' style={{maxWidth:"470px",width:"100%"}}>CHECK FARE</button>
</div>
        </div>
        {/* next roll */}
        <div className='col-lg-7 col-sm-12 mt-sm-3 container__2 ' style={{
    backgroundImage: `url(${herobg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }}>
<div className='d-flex justify-content-center align-items-center position-relative' style={{height:"100%"}}>
<div className='black__batch  bg-black text-white  rounded px-4' style={{position:"absolute" ,right:0,top:"10px",}}>
<p><img src={co2} style={{scale:"0.8"}}/>Every drive, Save CO2</p>
</div>
      <img src={heroinn} alt="Inner Image" style={{width:"80%",height:"80%",paddingTop:"80px",paddingBottom:"20px",position:"relative"}} />
</div>
    </div>
    </div>
    <div className='d-flex justify-content-center m-5'>
<div>
        {/* <center>
        <h5 ><b>20% OFF On App Booking!</b></h5>
                        <small ><b>*limited time offer</b></small>
                    </center> */}
                    <div className="downloadx d-flex gap-3">
                    <Link to="https://play.google.com/store/apps/details?id=com.noorride.user&hl=en&pli=1" target='_blank'>
                            <img src={playstore} alt="" />
                            </Link>
                        <Link to="https://apps.apple.com/in/app/noor-ride/id6499074054" target='_blank'>
                            <img src={applestore} alt="" />
                        </Link>

                    </div>
        </div>
</div>
</div>

    </>
  )
}
