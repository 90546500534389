import React from 'react'
import Aboutnoor from '../components/about/aboutnoor'
import Mission from '../components/about/mission'
import Aboutservice from '../components/about/aboutservice'
import Footer from '../components/Footer/Footer'
import AboutDriver from '../components/about/driver'
import AboutFamily from '../components/about/family'
import Explore from '../components/about/explore'

export default function About() {
  return (
    <div>
        <Aboutnoor/>
        <Mission/>
        <Aboutservice/>
        <AboutDriver/>
        <Explore/>
        <AboutFamily/>
        <Footer/>
    </div>
  )
}
