import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import playstore from '../../assets/images/playstore.png';
import applestore from '../../assets/images/applestore.png';

const APPDownloadNew = ({ show, handleClose ,price,disPrice}) => {
  return (
    <Modal show={show} onHide={handleClose}  centered backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Download Our App</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p>Get upto 20% off on your bookings through app</p>

        <h4 style={{color:"blue",fontWeight:"bold"}}>
          <span  style={{fontSize:"20px",color:"black"}}>AED<span  style={{textDecoration:"line-through",fontSize:"20px",textDecorationColor:"red",color:"black",fontWeight:"bold"}}>{parseFloat(price).toFixed(2)}</span></span>&nbsp;AED{parseFloat(disPrice).toFixed(2) || ""}
        </h4>
        <div className="app-store-images">
          <a href="https://play.google.com/store/apps/details?id=com.noorride.user&hl=en" target="_blank" rel="noopener noreferrer">
            <img src={playstore} alt="Play Store" />
          </a>
          <a href="https://apps.apple.com/in/app/noor-ride/id6499074054" target="_blank" rel="noopener noreferrer">
            <img src={applestore} alt="App Store" />
          </a>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
  <Button style={{background:"linear-gradient(to right, #49BC92, #49BC92)",letterSpacing:"2px"}} onClick={handleClose}>
   Ride for AED{parseFloat(price).toFixed(2)}
  
  </Button>
</Modal.Footer>
    </Modal>
  );
};

export default APPDownloadNew;
